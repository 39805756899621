import React from "react"
import { Text } from "deskie-ui"
import Container from "../components/Container"
import PageWrapper from "../components/PageWrapper"
import PlanMeeting from "../components/PlanMeeting"
import styled from "styled-components"
import Faq from "../components/Faq"

const Header = styled(Container)`
  margin-top: -6rem;
  padding-top: 8rem;
  padding-bottom: 6rem;

  h1 {
    color: white;
    font-weight: 600;
    font-size: min(
      max(1.75rem, calc(1.75rem + ((1vw - 0.4rem) * 1.875))),
      2.5rem
    );
    line-height: 1.3;
    margin: 0 0 0.5rem;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
  }

  background-image: url("/paths.svg");
  background-size: auto 160%;
  background-position: 100% 0%;
  background-repeat: no-repeat;
`

// const Content = styled(Container)`
//   h2 {
//     color: ${props => props.theme.colors.primary};
//   }

//   .button {
//     margin-top: 2rem;
//   }
// `

function FaqPage() {
  return (
    <>
      <Header>
        <Text h1>Veelgestelde vragen</Text>
      </Header>
      <PageWrapper>
        <Faq />
        <PlanMeeting />
      </PageWrapper>
    </>
  )
}

export default FaqPage
